<template>

    <div class="container-fluid persondetail margin-top-100" :key="person.slug" :title="person.title">
        <div class="row">

            <div class="col-xs-8 col-xs-offset-2 col-ms-6 col-ms-offset-3 col-sm-4 col-sm-offset-0 margin-bottom-25">
                <img v-if="person.img" :src="getImgUrl(person.img)" class="entry-person-detail">
            </div>

            <div class="col-xs-12 col-sm-8">
                <h1 v-if="person.title" class="detail-title">{{ person.title }}</h1>
                <p v-if="person.cv" v-html="person.cv" class="detail-body"></p>
            </div>

        </div>
    </div>

</template>

<script>
import json from '../assets/json/people.json'

// @ is an alias to /src
export default {
    name: 'PersonDetail',
    props: {
        slug: String
    },
    data() {
        return {
            people: json,
            person: undefined
        }
    },
    /*computed: {
        id () {
            return this.$route.params.id // From route
        }
    },*/
    created() {
        this.getPost();
    },
    watch: {
        $route() {
            // react to route changes...
            this.getPost();
        }
    },
    methods: {
        getPost() {
            let data = this.people.filter(item => {
                return item.slug == this.$route.params.slug
            })
            this.person = data[0]
        },
        getImgUrl: function(filename){
            return require('../assets/img/' + filename)
        }
    }
}
</script>
